/*
 * This file is part of Sift PHP framework.
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/**
 * This file is automatically generated by a build task for culture "cs_CZ".
 * Do not modify it manually. See the compiler script for more information.
 *
 * @link https://bitbucket.org/mishal/sift-php-framework/wiki/Javascript
 */

// add translation strings
I18n.addTranslation({"Alert":"Upozorn\u011bn\u00ed","Are you sure?":"Jste si jist\u00fd(\u00e1)?","Cancel":"Zru\u0161it","Choose time":"Vyberte \u010das","Close":"Zav\u0159\u00edt","Confirmation":"Potvrzen\u00ed","Empty":"Nevypln\u011bno","Hours":"Hodiny","Loading more results...":"Na\u010d\u00edt\u00e1m v\u00edce z\u00e1znam\u016f...","Loading...":"Na\u010d\u00edt\u00e1m...","Miliseconds":"Milisekundy","Minutes":"Minuty","Next":"Dal\u0161\u00ed","No matches found.":"\u017d\u00e1dn\u00e9 z\u00e1znamy nenalezeny.","Now":"Nyn\u00ed","Ok":"Ok","Please enter %number% more characters.":"Pros\u00edm vlo\u017ete je\u0161t\u011b %number% znak\u016f.","Previous":"P\u0159edchoz\u00ed","Searching...":"Hled\u00e1m...","Seconds":"Sekundy","The server is too busy at the moment.":"Server je nyn\u00ed p\u0159ili\u0161 zanepr\u00e1zdn\u011bn\u00fd.","This action requires to be logged in and you are not logged in. Please login.":"Tato akce vy\u017eaduje aby jste byl(a) p\u0159\u00edhl\u00e1\u0161ena, ale vy nejste. Pros\u00edm p\u0159ihla\u0161te se.","This action requires to be logged in and you are not logged in. Redirect to login page?":"Pro proveden\u00ed t\u00e9to akce je nutn\u00e9 b\u00fdt p\u0159ihl\u00e1\u0161en(a), ale vy nejste. P\u0159esm\u011brovat na p\u0159ihla\u0161ovac\u00ed str\u00e1nku?","Time":"\u010cas","Timezone":"\u010casov\u00e9 p\u00e1smo","Today":"Dnes","You can only select %number% item(s).":"M\u016f\u017eete vybrat jen %number% polo\u017eky(ek).","clear":"smazat","close":"zav\u0159\u00edt"});

// add culture information information
Globalize.addCultureInfo('cs-CZ', 'default', {"name":"cs_CZ","englishName":"Czech (Czech Republic)","isRTL":false,"numberFormat":{",":"\u00a0",".":",","NaN":"NaN","negativeInfinity":"+\u221e","positiveInfinity":"-\u221e","groupSizes":[3],"percent":{"pattern":["-n%","n%"],",":" ",".":""},"currency":{"pattern":["($n)","$n"],",":",",".":".","symbol":"$"}},"calendars":{"standard":{"name":"gregorian","firstDay":1,"days":{"names":["ned\u011ble","pond\u011bl\u00ed","\u00fater\u00fd","st\u0159eda","\u010dtvrtek","p\u00e1tek","sobota"],"namesAbbr":["ne","po","\u00fat","st","\u010dt","p\u00e1","so"],"namesShort":["N","P","\u00da","S","\u010c","P","S"]},"months":{"names":["leden","\u00fanor","b\u0159ezen","duben","kv\u011bten","\u010derven","\u010dervenec","srpen","z\u00e1\u0159\u00ed","\u0159\u00edjen","listopad","prosinec"],"namesAbbr":["Led","\u00dano","B\u0159e","Dub","Kv\u011b","\u010cer","\u010cvc","Srp","Z\u00e1\u0159","\u0158\u00edj","Lis","Pro"],"namesShort":["l","\u00fa","b","d","k","\u010d","\u010d","s","z","\u0159","l","p"]},"AM":["dop.","dop.","DOP."],"PM":["odp.","odp.","ODP."],"eras":{"name":"n. l.","start":null,"offset":0},"twoDigitYearMax":2029,"patterns":{"d":"d.M.yyyy","D":"d. MMMM y","t":"H:mm","T":"H:mm:ss z","f":"EEEE, d. MMMM y","F":"H:mm:ss zzzz","M":"MMMM dd","Y":"MMMM yyyy"}}}});

